<template>
  <div :class="{ 'inline-select--mini': !appendToBody }">
    <el-select
      ref="select"
      :key="_uid"
      v-select-close-on-blur
      :value="value"
      :default-first-option="true"
      :remote-method="remoteMethod"
      :loading="loading"
      :min="min"
      :popper-append-to-body="appendToBodyComputed"
      :popper-class="popperClassComputed"
      :multiple="multiple"
      :collapse-tags="collapseTagsComputed"
      autocomplete="sthap"
      size="mini"
      :filterable="false"
      :remote="remote"
      :clearable="clearable"
      placeholder="Select"
      @focus="$emit('focus', $event)"
      @visible-change="handleVisibleChange"
      @input="$emit('input', $event)">
      <el-option
        v-for="item in data"
        :key="itemValue(item)"
        :label="itemLabel(item)"
        :value="itemValue(item)"
        class="dropdown-filter-option">
        <slot name="option" :item="item">
          <template v-if="multiple">
            <div>
              <el-checkbox :value="value.includes(itemValue(item))" />
              {{ itemLabel(item) }}
            </div>
            <slot name="country" :countryName="item.code" />
          </template>
          <template v-else>
            <div class="dropdown-filter-option-default">
              <!-- ID -->
              <div v-if="optionStyle === 'default'" class="dropdown-filter-option-default__id">
                {{ itemValue(item) }}
              </div>

              <!-- TEXT -->
              <div class="dropdown-filter-option-default__name">
                {{ itemLabel(item) | truncate(15) }}
              </div>
            </div>

            <!-- Notification -->
            <span v-if="item.unread_notifications || (item.pending_requests !== null)" class="dropdown-filter-option__notification">
              <el-tag v-if="item.unread_notifications" :key="item.id" effect="dark" type="danger" size="mini">
                {{ item.unread_notifications }}
              </el-tag>
              <app-text v-else color="info" class="dropdown-filter-option__notification-notread">{{ item.pending_requests }}</app-text>
            </span>
          </template>
        </slot>
      </el-option>
    </el-select>
  </div>
</template>
<script>
import Breakpoints from '@/mixins/Breakpoints';
import AppText from '@/components/AppText';
import _isNull from 'lodash/isNull';
export default {
  name: 'DropdownFilter',
  components: { AppText },
  mixins: [Breakpoints([768])],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, String, Number],
      default: null
    },
    data: {
      type: [Array],
      required: true
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    optionStyle: {
      type: String,
      default: 'default'
    },
    remoteMethod: {
      type: Function,
      default: () => ({}),
      required: false
    },
    remote: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: null
    },
    collapseTags: {
      type: Boolean,
      default: null
    },
    popperClass: {
      type: String,
      default: null
    },
    collapseAfter: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      is_focus: false
    };
  },
  computed: {
    collapseTagsComputed() {
      return this.collapseTags ||
      (this.collapseAfter && Array.isArray(this.value) && this.value.length >= this.collapseAfter);
    },
    appendToBodyComputed() {
      return _isNull(this.appendToBody) ? this.windowBreakpoint >= 768 : this.appendToBody;
    },
    popperClassComputed() {
      let baseClass = 'dropdown-filter dropdown-popover';
      if (!this.appendToBody) baseClass += ' mw-550 inline';
      if (this.popperClass) baseClass += ` ${this.popperClass}`;
      return baseClass;
    }
  },
  methods: {
    handleVisibleChange() {
      this.is_focus = !this.is_focus;
      this.$emit('visible-change', this.is_focus);
    },
    itemLabel(item) {
      return item.name || this.itemValue(item);
    },
    itemValue(item) {
      return item[this.valueKey] || item.value || item;
    }

  }
};
</script>
<style lang="scss">
.dropdown-filter {
  .el-input__inner {
    min-height: 40px !important;

    .el-range-separator {
      height: 25px;
    }
  }

  .inner-checkbox {
    @apply tw-w-full tw-flex tw-justify-between;

    .el-checkbox__input.is-checked + .el-checkbox__label {
      @apply tw-font-semibold;
    }
  }

  &.is-multiple {
    .el-select-dropdown__item.selected::after {
      @apply tw-hidden;
    }
  }

  &.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    @apply tw-text-jb-ink;

    background-color: '#F9F9FC' !important;
  }

  &.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
    background: '#F9F9FC' !important;
  }

  &-option {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-items: center;

    &-default {
      display: flex;

      &__id {
        min-width: $--clb-layout-4;
      }

      &__name {
        color: $--clb-color-grey__grey-darker;
      }
    }

    &__notification {
      margin-left: $--clb-layout-1;
      font-weight: $--clb-font-weight__bold;
      font-size: $--clb-font-size-xs;

      &-notread {
        padding-right: $--clb-space-2;
      }
    }
  }

  &__info {
    float: right;
    color: $--clb-color-info;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    padding: 0 $--clb-space-2;
  }
}
</style>
