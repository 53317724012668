<template>
  <div class="campaign-feedback-form">
    <el-dialog
      v-scrollable:xy
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      title="Campaign Request">
      <transition name="el-fade-in" mode="out-in">
        <div v-if="hasFinishedSubmit" key="lottie" class="campaign-feedback-form__success">
          <h3>Request Sent!</h3>
          <p>Our team will review your request and be in touch as soon as possible.</p>
          <app-button @click="dialogVisible = false">
            <i class="el-icon-arrow-left" /> Discover Campaigns
          </app-button>
          <app-lottie-animation
            :animation-data="animationData"
            :height="400"
            :width="320"
            class="campaign-feedback-form__lottie" />
        </div>
        <el-form v-else key="form" :model="form">
          Briefly describe your ideal campaign. Our team will work to identify an existing opportunity, or prospect a new one that suits your needs. <br><br>
          <el-form-item label="Campaign Name / Vertical">
            <el-input
              v-model="form.name"
              :disabled="isLoading"
              size="mini"
              type="input"
              placeholder="Health & Wellness" />
          </el-form-item>
          <el-form-item label="Additional Details">
            <el-input
              v-model="form.details"
              :disabled="isLoading"
              type="textarea"
              size="mini"
              placeholder="I'm looking for..." />
          </el-form-item>
          <el-form-item label="Potential Opportunity">
            <el-input
              v-model="form.opportunity"
              :disabled="isLoading"
              class="campaign-feedback-form__input--number"
              size="mini"
              placeholder="100"
              type="number">
              <template slot="append">
                Units Per Week
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </transition>
      <span v-if="!hasFinishedSubmit" slot="footer" class="campaign-feedback-form__footer">
        <app-button
          :loading="isLoading"
          :disabled="!isFormValid || isLoading"
          class="campaign-feedback-form__footer-button"
          state="primary"
          size="mini"
          @click="handleSubmit">Submit</app-button>
      </span>
    </el-dialog>
    <transition name="badge-animate" mode="out-in">
      <div v-if="mounted && !dialogVisible && !hasFinishedSubmit" class="campaign-feedback-form__badge" @click="dialogVisible = true">
        <span>
          <p class="campaign-feedback-form__badge-heading">Trouble Finding Something?</p>
          <p class="campaign-feedback-form__badge-subheading">Let us find your perfect campaign</p>
        </span>
        <app-button state="primary" class="campaign-feedback-form__button" size="mini">
          Ask Us
        </app-button>
      </div>
    </transition>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';
import * as animationData from '@/assets/lottie/selfie.json';
import AppButton from '@/components/AppButton';

export default {
  name: 'CampaignFeedbackForm',
  components: {
    'el-dialog': Dialog,
    AppButton,
    AppLottieAnimation: () => import('@/components/AppLottieAnimation')
  },
  data() {
    return {
      mounted: false,
      isLoading: false,
      hasFinishedSubmit: false,
      dialogVisible: false,
      form: {
        details: '',
        name: '',
        opportunity: ''
      },
      animationData: animationData.default
    };
  },
  computed: {
    isFormValid() {
      return !!Object.values(this.form).filter(v => v !== '').length;
    },
    orgId() {
      return this.$store.getters.organization.id;
    }
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    handleClose(done) {
      this.dialogVisible = false;
      this.resetForm();
    },
    handleSubmit() {
      this.isLoading = true;

      this.$store
        .dispatch('submitFeedback', {
          orgId: this.orgId,
          data: this.form
        })
        .then(() => {
          this.isLoading = false;
          this.hasFinishedSubmit = true;
          this.resetForm();
        })
        .catch(e => {
          this.$store.dispatch('showErrorMessage', e.message || 'Failed to send request');
          this.isLoading = false;
        });
    },
    resetForm() {
      this.form = {
        details: '',
        name: '',
        opportunity: ''
      };
    }
  }
};
</script>

<style lang="scss">
.campaign-feedback-form {
  .el-dialog {
    height: 100%;
    width: 100%;
    margin: 0 !important;
  }

  .el-dialog__header {
    position: relative;
    z-index: 1;

    .el-dialog__title {
      font-size: $--clb-font-size-xl;
      font-weight: 700;
      color: $--clb-color__headings;
    }
  }

  .el-dialog__body {
    padding-top: $--clb-space-4;
    padding-bottom: $--clb-space-4;
    word-break: break-word;
    z-index: 0;
  }

  .el-form-item__label {
    font-weight: 700;
    color: $--clb-color__headings;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    &-button {
      width: 150px;
    }
  }

  &__success {
    text-align: center;

    h3 {
      text-align: center;
      font-size: $--clb-font-size-lg;
    }

    p {
      max-width: 300px;
      margin: 0 auto;
    }

    .app-button {
      margin: $--clb-layout-2 auto 72px auto;
      z-index: 1;
      position: relative;
    }

    .campaign-feedback-form__lottie {
      position: absolute;
      width: 100% !important;
      bottom: 0;
      left: 0;
      z-index: 0;
    }
  }

  .campaign-feedback-form__badge {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: $--clb-mobile-padding;
    font-size: $--clb-font-size-sm;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $--clb-border-color-base;
    box-shadow: $--box-shadow-dark;
    background: $--clb-color-primary__white;
    transition: transform $--clb-hover-transition, box-shadow $--clb-hover-transition, border $--clb-hover-transition, opacity $--clb-hover-transition;

    &-heading {
      font-size: $--clb-font-size-sm;
      font-weight: $--clb-font-weight__bold;
      flex: 2;
    }

    &-subheading {
      display: none;
      line-height: $--clb-font-size-base;
      font-size: $--clb-font-size-sm;
    }

    &:hover {
      cursor: pointer;
      border-color: $--clb-color-primary;
      background: $--color-light;

      &-heading,
      &-subheading {
        color: $--clb-color__headings;
      }
    }
  }

  &__button {
    border-radius: 100px;
    margin-left: $--clb-space-4;
    flex: 1;
    width: 100%;

    &:hover {
      transform: translateY(0) !important;
    }
  }
}

@media (min-width: $--xs) {
  .campaign-feedback-form {
    .el-dialog {
      margin: 15vh auto auto auto !important;
      width: 90%;
      height: auto;
    }

    &__success {
      .app-button {
        width: 320px;
      }
    }

    .campaign-feedback-form__badge {
      width: auto;
      padding: $--clb-layout-2;
      bottom: $--clb-space-2;
      right: $--clb-space-2;
      font-size: $--clb-font-size-base;
      border: 1px solid $--clb-border-color-base;
      border-radius: $--clb-border-radius;

      &-subheading {
        display: flex;
      }

      &:hover {
        transform: translateY(-6px);
      }
    }

    &__button {
      width: 100px;
    }
  }
}

@media (min-width: $--sm) {
  .campaign-feedback-form {
    .el-dialog {
      width: 100%;
      max-width: 600px;
    }

    .campaign-feedback-form__badge {
      right: $--clb-layout-2;
      bottom: $--clb-layout-2;
    }
  }
}
</style>
<style lang="scss" scoped>
.badge-animate-enter-active {
  transition: all 0.5s ease-out;
}

.badge-animate-leave-active {
  transition: all 0.3s ease-out;
}

.badge-animate-leave-active,
.badge-animate-enter {
  transform: translateY(200px);
  opacity: 0;
}

@media screen and (min-width: $--xs) {
  .badge-animate-leave-active,
  .badge-animate-enter {
    transform: translateX(200px);
    opacity: 0;
  }
}
</style>
