<template>
  <div v-if="Object.keys(formattedMetrics).length" class="campaign-metrics-list">
    <div class="campaign-metric-container">
      <campaign-metric
        v-for="(amount, type) in formattedMetrics"
        :key="type"
        :type="type"
        :amount="amount"
        class="campaign-metric" />
    </div>
  </div>
</template>

<script>
import CampaignMetric from '@/components/campaigns/CampaignMetric';
import dateService from '@/services/date';

export default {
  name: 'CampaignMetricsList',
  components: { CampaignMetric },
  props: {
    metrics: {
      required: true,
      type: Object
    }
  },
  computed: {
    isPrelaunch() {
      return this.metrics.status === 'prelaunch';
    },
    launchDate() {
      const date = dateService.parseISOString(this.metrics.launch_date);
      return `${dateService.getDayName(date)} ${dateService.getMonthNameAbbrv(date)} ${dateService.getDateOrdinal(date)}`;
    },
    /* eslint complexity: ["error", 20] */
    formattedMetrics() {
      const metrics = {};
      if (this.metrics.cpa) {
        metrics.cpa = this.formatCpa(this.metrics.cpa);
      }
      if (this.isPrelaunch) {
        metrics.launching = this.launchDate;
      }
      if (this.metrics.pairing_cpa) {
        metrics.upsell = this.formatCpa(this.metrics.pairing_cpa);
      }
      if (this.metrics.bepc) {
        metrics.bepc = '$' + this.metrics.bepc;
      }
      if (this.metrics.demand && this.metrics.demand === 'high') {
        metrics.demand = this.metrics.demand;
      }
      if (this.metrics.weekly_cap && this.$store.getters.userSubType === 'a1') {
        metrics.cap = this.metrics.weekly_cap;
      }
      if (this.metrics.demand && this.metrics.demand === 'medium') {
        metrics.demand = this.metrics.demand;
      }
      return metrics;
    }
  },
  methods: {
    formatCpa(cpa) {
      // Patch for breaking change from backend
      if (cpa && (cpa.indexOf('$') > -1 || cpa.indexOf('%') > -1)) return cpa;
      return '$' + (cpa > 99 ? parseInt(cpa) : parseFloat(cpa).toFixed(2));
    }
  }
};
</script>

<style lang="scss" scoped>
.campaign-metrics-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 54px;
  overflow: hidden;

  .campaign-metric-container {
    max-width: 100%;
    width: auto;
    height: auto;
    text-align: left;

    .campaign-metric {
      margin-bottom: 20px;
      min-width: 60px;
    }
  }
}
</style>
