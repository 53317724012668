<template>
  <div class="campaign-discover">
    <div class="campaign-discover__content">
      <section v-if="fetchingWinningCampaigns || winningCampaigns.length" class="campaign-discover__featured-campaigns">
        <app-heading level="h3" class="featured tw-mt-0">
          Top Performing <span class="hidden-xs tw-my-space-4">Campaigns</span>
        </app-heading>
        <app-glide
          :items="fetchingWinningCampaigns? new Array(8): winningCampaigns"
          :loading="fetchingWinningCampaigns"
          class="campaign-discover__content-promoted"
          @item-click="handleViewCampaignStory">
          <campaign-story-skeleton v-if="fetchingWinningCampaigns" slot-scope="row" :campaign="row.item" />
          <campaign-story
            v-else
            slot-scope="row"
            :campaign="row.item"
            :ranking="row.index+1" />
        </app-glide>
      </section>

      <section ref="recommended">
        <app-heading level="h3" class="tw-mb-space-4 tw-mt-0">
          Recommended <span class="hidden-xs ">Campaigns</span>
        </app-heading>
      </section>

      <section class="campaign-filters tw-mb-layout-3">
        <campaign-list-filters
          key="filter"
          :initial-filters="campaignFilters"
          class=" tw-bg-jb-indigo-lighter tw-p-space-4 tw-rounded"
          @filters-changed="handleFiltersChanged"
          @cancel="handleFiltersCancel" />
      </section>
      <app-infinite-scroll
        :key="campaignFiltersKey"
        :loading="fetchingRecommendedCampaigns"
        :current-page="currentPage"
        :total-pages="totalPages"
        @load-more="handleLoadMore">
        <template v-if="fetchingRecommendedCampaigns && firstLoad">
          <campaign-card-skeleton v-for="i of 9" :key="`campaign-card-skeleton-${i}`" />
        </template>
        <template v-else>
          <campaign-card
            v-for="campaign in campaigns"
            :key="'campaign-' + campaign.id + '-card'"
            :campaign="campaign"
            @click="handleViewCampaign(campaign)" />
        </template>
        <template v-slot:no-content>
          No Recommended Campaigns. <template v-if="hasCampaignFilters">
            <br> You have filters applied. <a @click="handleClearFiltersClick">Clear Filters</a>
          </template>
        </template>
        <template v-if="hasCampaignFilters" v-slot:end-of-list>
          No More Recommended Campaigns. <br> You have filters applied. <a @click="handleClearFiltersClick">Clear Filters</a>
        </template>
      </app-infinite-scroll>
      <campaign-feedback-form v-if="showFeedbackBadge" />
    </div>
  </div>
</template>

<script>
import AppGlide from '@/components/AppGlide';
import AppInfiniteScroll from '@/components/AppInfiniteScroll';
import CampaignCard from '@/components/campaigns/CampaignCard';
import CampaignCardSkeleton from '@/components/skeletons/campaigns/CampaignCardSkeleton';
import CampaignFeedbackForm from '@/sections/CampaignFeedbackForm';
import CampaignStory from '@/components/campaigns/CampaignStory';
import CampaignStorySkeleton from '@/components/skeletons/campaigns/CampaignStorySkeleton';
import CampaignListFilters from '@/components/campaigns/CampaignListFilters';
import AppHeading from '@/components/AppHeading';

export default {
  name: 'CampaignDiscover',
  components: {
    AppGlide,
    AppInfiniteScroll,
    CampaignCard,
    CampaignCardSkeleton,
    CampaignFeedbackForm,
    CampaignStory,
    CampaignStorySkeleton,
    CampaignListFilters,
    AppHeading

  },
  data() {
    return {
      fetchingRecommendedCampaigns: false,
      showFeedbackBadge: false,
      currentPage: 1,
      totalPages: 0,
      firstLoad: true
    };
  },
  computed: {
    campaignFilters() {
      return this.$store.getters.campaignFilters;
    },
    campaignFiltersKey() {
      return JSON.stringify(this.campaignFilters);
    },
    orgId() {
      return this.$store.getters.organization.id;
    },
    campaigns() {
      return this.$store.getters.recommendedCampaigns;
    },
    winningCampaigns() {
      return this.$store.getters.winningCampaigns;
    },
    fetchingWinningCampaigns() {
      return this.$store.getters.fetchingWinningCampaigns;
    },
    hasCampaignFilters() {
      return Object.entries(this.campaignFilters || {}).length > 0;
    }
  },
  watch: {
    campaignFilters(filters) {
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      const offset = Math.min(scrollY, scrollY + this.$refs.recommended.getBoundingClientRect().top - 60);
      scrollTo && scrollTo(0, offset);

      this.firstLoad = true;
      this.fetchRecommendations(1);
    }
  },
  created() {
    this.fetchRecommendations(1);
    this.fetchWinners();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.$store.dispatch('setFiltersVisibility', 'visible'));
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('setFiltersVisibility', 'hidden');
    next();
  },
  methods: {
    fetchRecommendations(page) {
      this.fetchingRecommendedCampaigns = true;
      this.showFeedbackBadge = this.showFeedbackBadge || page > 1;
      this.$api.campaigns
        .recommendations({
          orgId: this.orgId,
          params: Object.assign({ page }, { filter: this.campaignFilters }),
          storeAction: 'fetchRecommendations'
        })
        .then((response) => {
          this.fetchingRecommendedCampaigns = false;
          this.totalPages = response._meta.page_count;
          this.currentPage = response._meta.page;
          this.firstLoad = false;
          this.showFeedbackBadge = this.showFeedbackBadge || this.currentPage >= this.totalPages;
        })
        .catch((e) => {
          this.fetchingRecommendedCampaigns = false;
          throw e;
        });
    },
    fetchWinners() {
      this.$api.campaigns.winners({
        orgId: this.orgId,
        storeAction: 'fetchWinners'
      });
    },
    handleClickToggleFilters() {
      this.$store.dispatch('setFiltersVisibility', 'one-time-use');
    },
    handleLoadMore() {
      if (!this.fetchingRecommendedCampaigns) {
        this.fetchRecommendations(this.currentPage + 1);
      }
    },
    handleViewCampaignStory(campaign) {
      this.$store.dispatch('setNavigationStack', {
        apiCall: this.$api.campaigns.winners,
        name: 'winners',
        payload: {
          orgId: this.orgId,
          params: { page: 1 },
          storeAction: 'fetchWinners'
        },
        totalPages: 0,
        items: this.$store.getters.winningCampaignIds
      });
      this.$router.push(`/campaign/${campaign.id}`);
      scrollTo(0, 0);
    },
    handleViewCampaign(campaign) {
      this.$store.dispatch('setNavigationStack', {
        apiCall: this.$api.campaigns.recommendations,
        name: 'recommendations',
        payload: {
          orgId: this.orgId,
          params: { page: this.currentPage, filter: this.campaignFilters },
          storeAction: 'fetchRecommendations'
        },
        totalPages: this.totalPages,
        items: this.$store.getters.recommendedCampaignsIds
      });
      this.$router.push(`/campaign/${campaign.id}`);
      scrollTo(0, 0);
    },
    handleClearFiltersClick() {
      this.$store.dispatch('clearCampaignFilters');
    },
    handleFiltersChanged(campaignFilters) {
      this.$store.dispatch('setCampaignFilters', campaignFilters);
    },
    handleFiltersCancel() {
      this.$store.dispatch('setFiltersVisibility', 'hidden');
    }
  }
};
</script>

<style lang="scss">
@import '~@jumbleberry/el-tel-input/dist/elTelInput.css';

.campaign-discover {
  @include page-layout;

  h3 {
    @apply tw-text-headings tw-mb-layout-1;
  }

  &__content {
    &-promoted {
      @apply tw-mb-layout-2 xs:tw-mb-layout-1 sm:tw-mb-0;

      .campaign-story {
        @apply tw-mr-space-3;
      }
    }

    &.campaign-card {
      @apply tw-w-full;
    }
  }

  .infinite-scroll-list__transition-group {
    @apply tw-flex tw-flex-wrap tw-w-full;

    .campaign-card {
      @apply tw-flex-grow-0 tw-flex-shrink;
    }
  }

  &__featured-campaigns {
    @apply tw-mb-layout-3 md:tw-mb-space-6;
  }

  .flickity-page-dots {
    @apply tw--bottom-layout-3;
  }
}

@supports (display: grid) {
  .campaign-discover {
    .infinite-scroll-list__transition-group {
      @include responsive-grid(3);
    }
  }
}
</style>
