<template>
  <div class="campaign-list-filters tw-max-w-6xl">
    <section class="filter-group">
      <div class="xsm:tw-flex xsm:tw-justify-between left-cont">
        <div class="campaign-list-filters-vertical">
          <app-text type="h3" size="xs" weight="semi-bold" class="filter-group-title tw-mb-space-2 tw-m-0 tw-leading-3">
            Vertical
          </app-text>
          <div id="campaign-list-filters-vertical" class="tw-mb-space-2 2xl:tw-max-w-xs">
            <dropdown-filter
              v-model="vertical"
              :data="verticalList"
              value-key="name"
              :clearable="true"
              :remote="false"
              :multiple="true"
              :append-to-body="true"
              :collapse-after="3" />
          </div>
        </div>
        <div class="campaign-list-filters-countries">
          <app-text type="h3" size="xs" weight="semi-bold" class="filter-group-title tw-mb-space-2 tw-m-0 tw-leading-3">
            Country
          </app-text>
          <div id="campaign-list-filters-country" class="tw-mb-space-2 2xl:tw-max-w-xs">
            <dropdown-filter
              v-model="countries"
              value-key="code"
              :data="countryList"
              :clearable="true"
              :remote="false"
              popper-class="country-dropdown"
              :multiple="true"
              :append-to-body="true"
              :collapse-after="3">
              <template #country="{countryName}">
                <app-text>
                  {{ countryName }}
                </app-text>
              </template>
            </dropdown-filter>
          </div>
        </div>
        <div class="campaign-list-filters-features lgx:tw-block tw-hidden">
          <app-text type="h3" size="xs" weight="semi-bold" class="filter-group-title tw-mb-space-2 tw-m-0 tw-leading-3">
            Features
          </app-text>
          <div class="tw-mb-space-2 2xl:tw-max-w-xs">
            <dropdown-filter
              v-model="activeFeatures"
              :data="features"
              :clearable="true"
              :remote="false"
              :multiple="true"
              :append-to-body="true"
              :collapse-after="3" />
          </div>
        </div>
      </div>
      <div class="xsm:tw-flex xsm:tw-justify-between right-cont">
        <div class="campaign-list-filters-features lgx:tw-hidden">
          <app-text type="h3" size="xs" weight="semi-bold" class="filter-group-title tw-mb-space-2 tw-m-0 tw-leading-3">
            Features
          </app-text>
          <div class="tw-mb-space-2 2xl:tw-max-w-xs">
            <dropdown-filter
              v-model="activeFeatures"
              :data="features"
              :clearable="true"
              :remote="false"
              :multiple="true"
              :append-to-body="true"
              :collapse-after="3" />
          </div>
        </div>
        <div class="campaign-list-filters-refine">
          <app-text type="h3" size="xs" weight="semi-bold" class="filter-group-title tw-mb-space-2 tw-m-0 tw-leading-3">
            Refine by
          </app-text>
          <div class="filter-group__input tw-mb-space-2 2xl:tw-max-w-xs">
            <el-radio-group v-model="refinementName" class="tw-w-full">
              <el-radio-button
                v-for="(settings, _refinement) in refinements"
                :id="`filter-group__input-${_refinement}`"
                :key="_refinement"
                :label="_refinement"
                @click.native.prevent="handleRefinementClick(_refinement)">
                {{ _refinement }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <template v-if="refinement !== undefined">
            <vue-slider
              v-model="refinementRange"
              v-scrollable:x.ignore
              class="tw-mx-space-6"
              :dot-size="24"
              :height="10"
              :duration="0.3"
              :min="refinement.min"
              :max="refinement.max"
              :enable-cross="false"
              :min-range="refinement.minRange"
              :tooltip-formatter="tooltipFormat"
              tooltip="always"
              tooltip-placement="bottom" />
          </template>
        </div>
      </div>
    </section>
    <section class="app-button-filter-group-btn-cont tw-mt-space-4 lgx:tw-flex lgx:tw-justify-start lgx:tw-flex-row-reverse tw-text-center">
      <app-button
        :class="{'tw-bg-jb-indigo-disabled':!hasChanged || !filters }"
        class="app-button--block tw-bg-jb-indigo tw-font-semi-bold"
        @click="handleClickApplyFilters">
        Filter Campaigns
      </app-button>
      <transition name="el-fade-in" mode="out-in">
        <app-link v-if="hasNonEmptyFilters" class="!tw-text-jb-grey-700 lgx:tw-mr-space-3 lgx:tw-mt-space-3 tw-text-sm tw-font-medium" @click="clearFilters">
          Clear Filters
        </app-link>
      </transition>
    </section>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton';
import _pick from 'lodash/pick';
import AppText from '@/components/AppText';
import AppLink from '@/components/AppLink';
import DropdownFilter from '@/components/filters/DropdownFilter';
import _groupBy from 'lodash/groupBy';

export default {
  name: 'CampaignListFilters',
  components: {
    AppButton,
    AppText,
    AppLink,
    DropdownFilter

  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    initialFilters: {
      type: Object,
      default() {
        return {};
      }
    },
    showCancelButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      refinements: {
        CPA: {
          min: 0,
          max: 25,
          default: [6, 15],
          minRange: 3,
          prefix: '$',
          map: Array.apply(null, { length: 101 }).map(Number.call, i => i * 4)
        },
        BEPC: {
          min: 0,
          max: 20,
          default: [4, 20],
          minRange: 3,
          prefix: '$',
          map: Array.apply(null, { length: 101 }).map(Number.call, i => (i / 2).toFixed(2))
        },
        CAP: {
          min: 0,
          max: 5,
          default: [2, 5],
          map: [0, 100, 250, 500, 1000, 2500]
        },
        Demand: {
          min: 0,
          max: 2,
          default: [1, 2],
          map: ['low', 'medium', 'high']
        }
      },
      offer_type: { full_sale: 'Straight Sale', trial: 'Trial', cpl: 'CPL' },
      steps: { 2: 'Upsell' },
      activeFeatures: [],
      refinementName: undefined,
      refinementRange: [],
      countries: [],
      vertical: [],
      lastFilters: JSON.stringify(this.initialFilters)
    };
  },
  computed: {
    countryList() {
      return this.$store.getters.countries;
    },
    verticalList() {
      return this.$store.getters.verticals;
    },
    hasNonEmptyFilters() {
      return this.areFiltersNonEmpty(this.filters || {});
    },
    refinement() {
      return this.refinementName ? this.refinements[this.refinementName] : undefined;
    },
    refinementFilter() {
      if (!this.refinementName) return {};

      const map = this.refinement.map;
      const range =
        this.refinementName !== 'Demand'
          ? {
            min: map[this.refinementRange[0]],
            max: this.refinementRange[1] !== this.refinement.max ? map[this.refinementRange[1]] : null
          }
          : this.refinement.map.slice(this.refinementRange[0], this.refinementRange[1] + 1);

      return { [this.refinementName.toLowerCase()]: range };
    },
    features() {
      const features = Object.assign({}, this.steps, this.offer_type);
      return Object.keys(Object.assign({}, this.steps, this.offer_type)).map((key) => {
        return { id: key, name: features[key] };
      });
    },
    featureFilter() {
      return {
        offer_type: this.activeFeatures.concat([]).filter(v => Object.keys(this.offer_type).includes(v)),
        steps: this.activeFeatures.concat([]).filter(v => Object.keys(this.steps).includes(v))
      };
    },
    filters() {
      const filters = Object.assign(
        {
          countries: this.countries,
          vertical: this.vertical.map(v => v.toLowerCase())
        },
        this.refinementFilter,
        this.featureFilter
      );

      return this.areFiltersNonEmpty(filters) ? filters : {};
    },
    hasChanged() {
      return JSON.stringify(this.filters) !== this.lastFilters;
    }
  },
  watch: {
    initialFilters(filters) {
      if (!this.areFiltersNonEmpty(filters) && this.hasNonEmptyFilters) {
        this.clearFilters();
      }
    }
  },
  created() {
    if (this.areFiltersNonEmpty(this.initialFilters)) {
      this.restoreSavedFilters(this.initialFilters);
      // Fix existing state for users who have already set empty filters
    } else if (Object.entries(this.initialFilters).length > 0) {
      this.clearFilters();
    }

    this.$api.organization.fetchCountries({
      storeAction: 'fetchCountries'
    });

    this.$api.organization.fetchVerticals({
      storeAction: 'fetchVerticals'
    });
  },
  methods: {
    restoreSavedFilters(filters) {
      this.countries = filters.countries;
      this.vertical = filters.vertical;
      this.activeFeatures = [...filters.offer_type, ...filters.steps];
      const refinement = _pick(
        filters,
        Object.keys(this.refinements).map(r => r.toLowerCase())
      );
      if (Object.keys(refinement).length) {
        this.refinementName = Object.keys(this.refinements).find(r => r.toLowerCase() === Object.keys(refinement)[0]);
        const lowerValue = Object.values(Object.values(refinement)[0])[0];
        const upperValue = Object.values(Object.values(refinement)[0])[1];
        const lowerIndex = lowerValue
          ? this.refinements[this.refinementName].map.indexOf(lowerValue)
          : this.refinements[this.refinementName].default[0];
        const upperIndex = upperValue
          ? this.refinements[this.refinementName].map.indexOf(upperValue)
          : this.refinements[this.refinementName].default[1];
        this.refinementRange = [lowerIndex, upperIndex];
      }
    },
    handleRefinementClick(refinement) {
      this.refinementName = refinement !== this.refinementName ? refinement : undefined;
      this.refinementRange = this.refinementName ? this.refinement.default.concat([]) : [];
    },
    clearFilters() {
      this.countries = [];
      this.vertical = [];
      this.activeFeatures = [];
      this.refinementName = undefined;
      this.handleClickApplyFilters();
    },
    handleClickApplyFilters() {
      this.lastFilters = JSON.stringify(this.filters);
      this.$emit('filters-changed', JSON.parse(this.lastFilters));
    },
    areFiltersNonEmpty(filters) {
      return Object.keys(filters || {}).some((k, v) => {
        return typeof filters[k] !== 'object' || Object.entries(filters[k]).length > 0;
      });
    },
    tooltipFormat(val) {
      return (
        (this.refinement.prefix || '') +
        this.$options.filters.capitalize(this.refinement.map[val] + '') +
        (this.refinement.max === val && this.refinementName !== 'Demand' ? '+' : '')
      );
    }
  }
};
</script>

<style lang="scss">

.campaign-list-filters {
  .filter-group {
    @media (min-width: 1300px) {
      @apply tw-flex tw-justify-between;
    }

    input {
      &::placeholder {
        @apply tw-text-jb-ink;
      }
    }

    .el-input__inner {
      min-height: 36px !important;
      height: 36px !important;
    }

    .vue-slider-dot-tooltip-inner {
      @apply tw-leading-5 tw-p-space-2 tw-text-sm tw-bg-jb-indigo;
    }

    .vue-slider-ltr .vue-slider-process {
      @apply tw-bg-jb-indigo;
    }

    @media (min-width: 600px) {
      .campaign-list-filters-vertical,
      .campaign-list-filters-countries,
      .campaign-list-filters-refine,
      .campaign-list-filters-features, {
        width: 48%;
      }
    }

    @media (min-width: 1300px) {
      .campaign-list-filters-vertical,
      .campaign-list-filters-features,
      .campaign-list-filters-countries {
        width: 32%;
      }

      .campaign-list-filters-refine, {
        width: 246px;
      }

      .left-cont {
        margin-right: 1%;

        @apply tw-w-full;
      }

      .right-cont {
        margin-left: 0.5%;
        justify-content: flex-end !important;
      }
    }
  }

  .el-radio-button__inner {
    @apply tw-text-jb-ink tw-text-sm tw-bg-white tw-py-space-2 tw-px-space-3;
  }

  .el-radio-button__orig-radio:checked,
  .el-radio-button__orig-radio:hover {
    + .el-radio-button__inner {
      @apply tw-border-jb-indigo tw-rounded;
    }
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    @apply tw-bg-light-bg-color tw-text-jb-ink;
  }

  .app-button {
    @media (min-width: 1300px) {
      width: auto;
    }
  }
}

</style>
