<template>
  <div class="campaign-card-skeleton">
    <skeleton-box height="280px" width="100%" />
    <div class="campaign-card-skeleton__bottom">
      <skeleton-box height="14px" width="92%" class="  content-1" />
      <skeleton-box height="14px" width="100%" class="metric content-2" />
      <skeleton-box height=" 14px" width="92%" class=" content-1" />
    </div>
  </div>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'CampaignCardSkeleton',
  components: { SkeletonBox }
};
</script>

<style lang="scss" scoped>
.campaign-card-skeleton {
  width: 100%;
  border: 1px solid $--clb-border-color-base;
  border-radius: $--clb-border-radius;

  .skeleton-box {
    border-radius: $--clb-border-radius;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 74px;
    padding: $--clb-space-4 $--clb-space-3;
    background: #fff;
    border-radius: $--clb-border-radius;

    .metric {
      margin: $--clb-space-1 0;
    }

    .content-1 {
      margin: 0 auto;
    }
  }
}
</style>
