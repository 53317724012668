<template>
  <div class="campaign-story">
    <app-preview-card
      v-if="campaign"
      size="medium"
      width="130"
      height="200"
      :background-image="campaignImage"
      :overlay-number="ranking"
      :overlay="campaign.name" />
  </div>
</template>

<script>
import imageService from '@/services/image-service';
import AppPreviewCard from '@/components/AppPreviewCard';

export default {
  components: { AppPreviewCard },
  props: {
    campaign: {
      type: Object,
      required: false,
      default: undefined
    },
    ranking: {
      type: Number,
      required: false,
      default: undefined
    }
  },
  computed: {
    campaignImage() {
      return this.campaign
        ? imageService.resizeImage(this.campaign.image_desktop, {
          auto: 'enhance,format',
          fit: 'crop',
          crop: 'focalpoint',
          h: 200,
          w: 168,
          fm: 'jpeg',
          q: 85
        })
        : null;
    }
  }
};
</script>
