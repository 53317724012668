<template>
  <div class="infinite-scroll-list">
    <transition-group
      :class="gridClassName"
      :css="false"
      name="el-fade-in"
      tag="div"
      class="infinite-scroll-list__transition-group"
      @before-enter="beforeEnter"
      @enter="enter">
      <slot />
      <slot v-if="loading" name="loading">
        <div key="loading" v-loading="loading" class="infinite-scroll-list__loader" />
      </slot>
      <div v-else-if="totalPages === 0" key="no-content" class="infinite-scroll-list__no-content">
        <slot name="no-content">
          No Content
        </slot>
      </div>
      <div v-if="currentPage === totalPages" key="end-of-list" class="infinite-scroll-list__end-of-list">
        <slot name="end-of-list" />
      </div>
    </transition-group>
    <div ref="stopper" class="infinite-scroll-list__stopper" />
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';

export default {
  name: 'AppInfiniteScroll',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number,
      default: 0
    },
    totalPages: {
      type: Number,
      default: 0
    },
    enableFirstTransition: {
      type: Boolean,
      default: false
    },
    breakPoints: {
      type: Object,
      default: function() {
        return { xs: 1, sm: 2, md: 2, lg: 3, xl: 3 };
      }
    },
    querySelector: {
      type: String,
      default: 'window'
    }
  },
  data() {
    return {
      windowEventListener: _debounce(this.checkStopperVisibility, 64, {
        maxWait: 64,
        trailing: true
      }),
      nextTransitionTime: 0,
      firstLoad: false
    };
  },
  computed: {
    hasMorePages() {
      return this.currentPage < this.totalPages;
    },
    shouldTransition() {
      return this.enableFirstTransition || this.firstLoad;
    },
    gridClassName() {
      const keys = Object.keys(this.breakPoints);
      return keys.map(item => `${item}-${this.breakPoints[item]}`).join(' ');
    },
    scrollContainer() {
      return this.querySelector !== 'window' ? document.querySelector(this.querySelector) : window;
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler() {
        this.shouldTransition && this.checkStopperVisibility();
      }
    }
  },
  mounted() {
    this.scrollContainer.addEventListener(
      'scroll',
      this.windowEventListener,
      this.$supportsPassive && { passive: true }
    );
    this.checkStopperVisibility();
  },
  beforeDestroy() {
    this.scrollContainer.removeEventListener(
      'scroll',
      this.windowEventListener,
      this.$supportsPassive && { passive: true }
    );
  },
  methods: {
    beforeEnter(cardListItem) {
      if (this.shouldTransition) {
        cardListItem && cardListItem.setAttribute('style', 'transform: translateX(-50px);opacity: 0;');
      }
    },
    enter(cardListItem, done) {
      if (this.shouldTransition) {
        this.nextTransitionTime = Math.max(this.nextTransitionTime + 150, new Date().getTime() + 150);
        setTimeout(() => {
          cardListItem && cardListItem.setAttribute('style', 'transform: translateX(0px);opacity: 1;');
        }, this.nextTransitionTime - new Date().getTime());
      }
      done();
    },
    checkStopperVisibility() {
      if (!this.loading && this.isVisible(this.$refs.stopper) && this.hasMorePages) {
        this.$emit('load-more');
        this.firstLoad = true;
      }
    },
    isVisible(elm) {
      if (!elm || !elm.offsetParent) {
        return false;
      }

      const clientHeight = this.scrollContainer.clientHeight || document.documentElement.clientHeight;
      const viewHeight = Math.max(clientHeight, window.innerHeight);
      const rect = elm.getBoundingClientRect();
      return rect.top <= viewHeight;
    },
    getGridClassName() {
      const keys = Object.keys(this.breakPoints);
      return keys.map(item => `${item}-${this.breakPoints[item]}`).join(' ');
    }
  }
};
</script>

<style lang="scss">
.infinite-scroll-list {
  overflow-anchor: none;

  &__transition-group {
    width: 100%;
  }

  &__stopper {
    height: 1px;
    width: 1px;
    pointer-events: none;
    position: relative;
    bottom: 800px;
    z-index: -1;
    background-color: transparent;
  }

  &__loader {
    margin: $--clb-layout-4 0 0 0;
    min-height: 48px;
    grid-column: 1 / -1;

    .el-loading-mask {
      background-color: transparent;
    }
  }

  &__no-content,
  &__end-of-list {
    text-align: center;
    color: $--color-info;
    margin: $--clb-layout-4 0 0;
    font-weight: 300;
    min-height: 48px;
    line-height: 24px;
    grid-column: 1 / -1;
  }
}

@media (min-width: $--xs) {
  .infinite-scroll-list {
    padding: 0;
  }
}
</style>
