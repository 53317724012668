<template>
  <app-card :class="[`campaign-card--${campaign.approval_status}`, `campaign-card--${campaign.status}`]" class="campaign-card" @click="$emit('click')">
    <div class="campaign-card__bg">
      <div :style="`background-image:url(${campaignImage});`" class="campaign-card__bg-image" />
      <div class="campaign-card__header">
        <div class="campaign-card__header__title">
          <app-country-icon :countries="campaign.countries" :limit="4" class="app-country-icon" />
          <h2 class="campaign-card__header__name">
            {{ campaign.name }} <i v-if="approvalIcon" :class="approvalIcon" class="campaign-card__header__icon" />
          </h2>
          <h5 class="campaign-card__header__desc">
            {{ campaign.vertical }}
          </h5>
        </div>
      </div>
      <campaign-metrics-list :metrics="campaign.metrics" class="campaign-card__pill" />
    </div>
    <template v-slot:footer>
      <div class="status" />
      <div class="campaign-card__footer">
        <p>{{ campaign.description | breakWords(20) }}</p>
      </div>
    </template>
  </app-card>
</template>
<script>
import AppCard from '@/components/AppCard';
import AppCountryIcon from '@/components/AppCountryIcon';
import Breakpoints from '@/mixins/Breakpoints';
import CampaignMetricsList from '@/components/campaigns/CampaignMetricsList';
import imageService from '@/services/image-service';

export default {
  components: { CampaignMetricsList, AppCard, AppCountryIcon },
  mixins: [Breakpoints([768, 992])],
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  computed: {
    approvalIcon() {
      return { approved: 'el-icon-success' }[this.campaign.approval_status] || false;
    },
    campaignImage() {
      const windowBreakpoint = this.windowBreakpoint;
      const imageWidth = windowBreakpoint >= 992 ? 320 : windowBreakpoint >= 768 ? 436 : 680;

      return imageService.resizeImage(this.campaign.image_desktop, {
        auto: 'enhance,format',
        fm: 'jpeg',
        q: 85,
        fit: imageWidth > 480 ? 'min' : 'crop',
        crop: 'focalpoint',
        h: 280,
        w: imageWidth
      });
    }
  }
};
</script>
<style lang="scss">
.campaign-card {
  width: 100%;
  transition: $--clb-hover-transition;
  background: #fff;
  border-radius: $--clb-border-radius;
  border: 1px solid $--clb-border-color-base;

  &__header {
    &__title {
      width: 100%;

      .app-country-icon {
        float: right;
        margin-left: 12px;
      }
    }

    &__icon {
      background: #81cf74;
      border-radius: 100%;
      width: 13px;
      height: 14px;
      text-indent: -1px;
      line-height: 14px;
      margin-left: 4px;
    }

    &__desc {
      text-transform: capitalize;
    }
  }

  &__bg {
    padding: 0;
    height: 280px;
    top: -4px;
    position: relative;
    background-color: $--clb-skeleton-color;
    overflow: hidden;

    &-image {
      position: absolute;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      transition: transform $--clb-hover-transition;
      transform: scale(1.01);
    }

    .campaign-card__header {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;

      /* eslint-disable-next-line */
      background:
        linear-gradient(
          to bottom,
          rgba(66, 66, 66, 0.4),
          cubic-bezier(0.445, 0.05, 0.55, 0.95),
          rgba(33, 33, 33, 0)
        );
      padding: $--clb-space-4 $--clb-space-4 $--clb-layout-5 $--clb-space-4;

      &__desc,
      &__name {
        font-size: $--clb-font-size-base;
        margin: 0;
        line-height: 24px;
        color: $--clb-color-primary__white;
        text-shadow: 0 0 3px rgb(33, 33, 33);
        overflow: hidden;
        display: block;
      }

      &__desc {
        font-size: $--clb-font-size-base - 1px;
        line-height: 16px;
        font-weight: 500;
        text-shadow: 0 0 2px rgb(33, 33, 33);
      }

      &__date {
        height: 100%;
        margin-left: auto;
      }
    }
  }

  &__pill {
    border: 1px solid $--clb-border-color-base;
    position: absolute;
    float: right;
    margin: 0 $--clb-space-4 $--clb-space-6 $--clb-space-4;
    padding: 12px 16px 12px 24px;
    border-radius: 100px;
    background-color: #fff;
    color: $--jb-survey-sub-header;
    bottom: 0;
    right: 0;
    box-shadow: 0 2px 8px 0 $--clb-shadow-color;
    max-width: 300px;

    .campaign-metric-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  }

  &__footer {
    padding: 16px;
    font-weight: 500;

    p {
      max-height: 42px;
      font-size: $--clb-font-size-xs;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .progress::after {
    background: #333;
    width: 200px;
    height: 6px;
  }

  &:hover {
    border: 1px solid $--clb-color-primary;
    background: $--color-light;
    box-shadow: $--box-shadow-light;

    .campaign-card__bg-image {
      transform: scale(1.06);
    }

    .campaign-card__footer p {
      color: $--clb-body-font;
    }
  }

  .app-card__body {
    position: relative;
  }

  &--prelaunch,
  &--approved,
  &--pending {
    .app-card__body::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  &--prelaunch {
    .app-card__body::before {
      background-color: $--clb-color-pink;
    }
  }

  &--approved {
    .app-card__body::before {
      background-color: #81cf74;
    }
  }

  &--pending {
    .app-card__body::before {
      background-color: $--color-info;
    }
  }
}
</style>
